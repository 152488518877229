<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="7" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-form>
                        <v-toolbar color="secondary" class="white--text mb-4">
                            <v-toolbar-title>
                                Whatsapp Template
                            </v-toolbar-title>
                        </v-toolbar>
                        <AError :api="this.api"/>
                    
                        <!--BOC : Create a form here-->
                        <v-container>
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">

                                <v-text-field ref="name" :rules="rules" v-model="name" label="Name" dense outlined>
        
                                </v-text-field>
                            </div>

                            <div class="d-flex flex-row">
                                <v-textarea ref="message" :rules="rules" v-model="message" label="Message..."  dense outlined>

                                </v-textarea>
                            </div>

                            <div class="d-flex flex-row">
                                <v-select :items="accessibilityOption" v-model="accessibility" dense outlined label="Accessibility">

                                </v-select>
                            </div>

                            <div class="d-flex justify-end">
                                <v-btn color="primary" plain @click="redirectBack">
                                    Cancel
                                </v-btn>
                                <v-btn color="primary" :loading="api.isLoading" @click="validateInput">
                                    Submit
                                </v-btn>
                            </div>
                            
                        </div>
                        </v-container>
                    </v-form>

                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import AError from '../../components/common/AError.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    AError,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rules:[
            cannotEmpty=>!!cannotEmpty||'This field is required',
        ],
        accessibilityOption:["public",'private'],
        accessibility:null,
        isPending:false,
        name:null,
        message:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    
    }),
    created() {
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.status=="failed"){
                this.api.isError = true;
                this.api.error = resp.data;
                this.api.isLoading = false;
                return 0;
            }
            if(resp.class=="getWhatssapTemplate"){
                this.name = resp.data.whatssap_name;
                this.message = resp.data.message;
                this.accessibility = resp.data.accessibility;
            }
            if(resp.class=="updateWhatssapTemplate"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update The Template";
            }
            this.api.isError = false;
            this.api.isLoading = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchWhatssapTemplateApi = this.fetchWhatssapTemplate();
            this.$api.fetch(fetchWhatssapTemplateApi);
        },
        submit(){
            this.isPending = false;
            let updateTemplateApi= this.updateTemplate();
            this.$api.fetch(updateTemplateApi);
        },
        fetchWhatssapTemplate(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/whatssap/template/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            if(this.name==null){
                this.$refs.name.focus();
                return false;
            }
            if(this.message==null){
                this.$refs.message.focus();
                return false;
            }
            this.isPending = true;
        },
        redirectBack(){
            this.$router.go(-1);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        updateTemplate(){


            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT"; 
            tempApi.url = process.env.VUE_APP_SERVER_API+"/whatssap/template/"+this.$router.history.current.params.id;

            tempApi.params = {
                "name":this.name,
                'message':this.message,
                'accessibility':this.accessibility,
            };

            return tempApi;
        },
     
    }
}
</script>